body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: Roboto;
}

.opacity-06 {
  opacity: 0.6 !important;
}

.font-12 {
  font-size: 12px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 15px !important;
}

.mt-3 {
  margin-top: 24px !important;
}

.mt-5 {
  margin-top: 34px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-05 {
  margin-bottom: 5px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 15px !important;
}

.mb-3 {
  margin-bottom: 24px !important;
}

.mv-2 {
  margin-bottom: 8px;
  margin-top: 8px;
  display: inline-block;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.p-0 {
  padding: 0px !important;
}

.pt-5 {
  padding-top: 34px !important;
}

.p-16 {
  padding: 16px !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mx-05 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mx-1 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mx-2 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mr-05 {
  margin-right: 5px !important;
}

.mr-1 {
  margin-right: 10px !important;
}

.mr-2 {
  margin-right: 15px !important;
}

.mr-3 {
  margin-right: 24px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 15px !important;
}

.ml-3 {
  margin-left: 24px !important;
}

.ml-4 {
  margin-left: 34px !important;
}

.ml-5 {
  margin-left: 40px !important;
}

.ml-auto {
  margin-left: auto;
}

.ml-a-80 {
  margin-left: -80px;
}

.mr-a-80 {
  margin-right: -80px;
}

.b-0 {
  border: 0 !important;
}
/*  */
.text-center {
  text-align: center;
}

.border-right {
  border-right: 1px solid rgba(212, 158, 255, 0.1);
}

.d-flex {
  display: flex;
}

.d-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.d-flex-center {
  display: flex !important;
  align-items: center !important;
}

.d-flex-justify-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.d-flex-center-between {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.d-flex-between {
  display: flex !important;
  justify-content: space-between !important;
}

.d-inline {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.f-left {
  float: left;
}

.align-center {
  align-items: center;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.fix-inner-ellipsis .ant-space-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.word-break {
  word-break: break-word;
}

.nowrap {
  white-space: nowrap;
}

.bg-transparent {
  background: transparent !important;
  border: 0 !important;
}

/* style submenu */
.neon-sub-menu {
  background: rgba(74, 47, 74, 1) !important;
  backdrop-filter: blur(500px) !important;
  border-radius: 8px !important;
}

/* style dropdown */
.ant-select * {
  box-shadow: 0 0 0 !important;
}

.ant-select-dropdown {
  background: #4a2f4a !important;
  backdrop-filter: blur(500px) !important;
  border-radius: 8px !important;
  padding: 0 !important;
  /* min-width: 60px !important; */
}

.st-dropdown-network.ant-select-dropdown {
  text-align: left;
  min-width: 110px !important;
  background-color: #21181f !important;
  /* padding: 0 10px !important; */
}
.solc-ver .ant-select-item,
.optimazation .ant-select-item,
.compiler-type .ant-select-item {
  color: #fcf6fe !important;
}
.solc-ver .ant-select-item-option-active:not(.ant-select-item-option-disabled),
.solc-ver
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.optimazation
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.optimazation
  .ant-select-item-option-active:not(.ant-select-item-option-disabled),
.compiler-type
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.compiler-type
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #201720;
}

.st-dropdown-theme *,
.st-dropdown-network * {
  background: transparent !important;
}
.st-dropdown-theme .ant-select-item,
.st-dropdown-network .ant-select-item {
  padding: 10px !important;
  color: #fcf6fe !important;
  border-bottom: 1px solid rgba(238, 200, 247, 0.1);
}

.st-dropdown-network .ant-select-item {
  padding: 15px 10px 15px 35px !important;
  background: url("./assets/images/icon-radio.svg") no-repeat 15px center !important;
  background-size: 14px !important;
}

.st-dropdown-theme .ant-select-item:last-child,
.st-dropdown-network .ant-select-item:last-child {
  border: 0;
}

.st-dropdown-theme .ant-select-item-option-active,
.st-dropdown-network .ant-select-item-option-active {
  color: #c74ae3 !important;
}

.st-dropdown-network .ant-select-item-option-active {
  background-image: url("./assets/images/icon-radio-active.svg") !important;
}

.st-dropdown-network .ant-select-item-option-disabled {
  background-color: #4a2f4a !important;
  color: rgba(238, 200, 247, 0.6) !important;
}
/* Responsive */

@media only screen and (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 24px !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .d-lg-up-block {
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
  .d-flex-xs-justify-between {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .d-flex-xs-justify-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .w-xs-100 {
    width: 100%;
  }
  .mt-xs-2 {
    margin-top: 15px !important;
  }
  .d-md-down-block {
    display: block !important;
  }
}

@media only screen and (max-width: 991px) {
  .mx-md-down-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow: hidden;
  }
  .ml-a-80 {
    margin-left: -15px;
  }
  .mr-a-80 {
    margin-right: -15px;
  }
}

.st-icon404 {
  display: flex;
  align-items: center;
  height: 100%;
}

.st-icon404 img {
  max-width: 100%;
  height: 100%;
}
